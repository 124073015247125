.App {
  text-align: center;
  padding: 3rem
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login-page-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form-container {
  display: flex;
  flex-flow: column;
  min-width: 25vw;
}

.btn-style {
  margin-top: 1rem;
}

/* CSS */
.button-50 {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, #000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: background-color 500ms ease-out;
  -moz-transition: background-color 500ms ease-out;
  -o-transition: background-color 500ms ease-out;
  transition: background-color 500ms ease-out;
}

.button-50:focus {
  text-decoration: none;
}

.button-50:hover {
  background-color: #5f5f5fbf;
  text-decoration: none;
}

.button-50:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.button-50:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}

/* DROPZONE */

.file-uploader {
  background-color: white;
  border: solid 0.08rem rgb(173, 173, 173);
  border-radius: 0.2rem;
  color: black;
  display: flex;
  flex-direction: column;
}

.file-drop {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file-drop-zone {
  transition: .2s ease-in-out;
}

.file-drop-zone>* {
  height: 20vh;
}

.file-drop-zone:hover {
  cursor: pointer;
  border: solid 0.08rem #1890ff;
}

.file-drop-section {
  flex-grow: 1;
  height: auto;
  border: solid 0.08rem rgb(173, 173, 173);
  border-radius: 0.2rem;
}

.file-drop>* {
  margin: 1rem;
  width: min-content;
}

.file-drop .message {
  display: flex;
  margin: auto;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 100%;
  font-weight: bold;
  text-align: center;
}

.grid-selected-files {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.file-completed {
  text-decoration: line-through;
  color: green;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
